<script setup>
import BooksBannerBackground from '~/components/banners/BooksBannerBackground'

const props = defineProps({
  bannerType: {
    type: String,
    validator(value) {
      return ['static', 'video', 'books'].includes(value)
    },
    default: 'books'
  },
  canBuySubscription: {
    type: Boolean,
    default: true
  },
  backgroundColor: {
    type: String,
    default: '#C7E8F5'
  },
  iconColor: {
    type: String,
    default: undefined
  }
})

const config = useRuntimeConfig()
const videoSrc = `${config.public.AZURE_STORAGE_URL}/fe-assets/homepage-banner-video.mp4`
const src = `${config.public.IMAGE_RESIZE_URL}?image=fe-assets/homepage-banner.png`
const sources = `
    ${src}&width=750&height=752&fit=cover 1000w,
    ${src}&width=1440&height=661&fit=contain 1500w,
    ${src}&width=2880&height=1322&fit=pad 1800w`

const { $label } = useNuxtApp()
const btnLabel = computed(() => {
  return props.canBuySubscription
    ? $label('common.joinUs')
    : $label('page.home.startReadingBtn')
})

const router = useRouter()
function onBtnClick() {
  const link = props.canBuySubscription ? 'aanmelden' : 'boeken'

  router.push(link)
}
</script>

<template>
  <div
    :class="[
      'homepage-banner position-relative overflow-hidden',
      `homepage-banner--${bannerType}`
    ]"
  >
    <div
      class="homepage-banner_img position-absolute w-100 h-100"
      :style="{ backgroundColor }"
    >
      <video v-if="bannerType === 'video'" autoplay loop muted playsinline>
        <source :src="videoSrc" type="video/mp4" />
      </video>

      <img
        v-else-if="bannerType === 'static'"
        :src="src"
        :srcset="sources"
        width="1440"
        height="661"
      />
    </div>

    <div class="position-relative container">
      <div class="homepage-banner__content row position-relative">
        <slot name="content">
          <div class="col col-lg-6 col-xl-5">
            <h1 class="mb-16 mb-md-24">
              {{ $label('page.home.headerTitle') }}
            </h1>
            <p class="text-large mb-16 mb-md-24">
              {{ $label('page.home.headerSubtitle') }}
            </p>

            <BaseButton
              variant="call-to-action"
              class="become-member-btn mb-16 mb-md-48"
              @click="onBtnClick"
            >
              {{ btnLabel }}
            </BaseButton>

            <p class="mb-0 text-small fst-italic">
              {{ $label('page.home.headerCaption') }}
            </p>
          </div>
        </slot>
      </div>
    </div>

    <template v-if="bannerType === 'books'">
      <slot name="books-extra-content">
        <p class="text-small md-text-small fst-italic">
          {{ $label('page.home.headerCaption') }}
        </p>
      </slot>

      <BooksBannerBackground :icon-color="iconColor" />
    </template>
  </div>
</template>

<style lang="scss">
.homepage-banner {
  color: $white;
  padding: 8% 0 28%;

  img,
  video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  h1 {
    color: $white;
  }

  .md-text-small {
    display: none;
  }

  .become-member-btn {
    width: 100%;
  }
}

.homepage-banner_img {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.homepage-banner_img::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: linear-gradient(
    180deg,
    rgba(19, 19, 19, 0.28) 75.69%,
    rgba(0, 0, 0, 0) 95.76%
  );
}

.homepage-banner--books {
  text-align: left;
  padding: 40px 0 16px;
  color: $black;

  .homepage-banner_img::after {
    background: unset;
  }
  .homepage-banner__content {
    display: flex;
    justify-content: center;
    z-index: 2;
  }

  h1 {
    max-width: 600px;
    color: $black;
  }

  .text-large {
    max-width: 694px;
  }

  .become-member-btn {
    width: unset;
  }
}

@include media-breakpoint-up(md) {
  .homepage-banner {
    padding: 14% 0 17%;

    .become-member-btn {
      padding: 14.5px 20px 14.5px 20px;
      font-size: 18px;
      line-height: 21px;
    }
  }

  .homepage-banner_img::after {
    background: linear-gradient(
      83.17deg,
      rgba(19, 19, 19, 0.42) 39.46%,
      rgba(0, 0, 0, 0) 81.67%
    );
  }

  .homepage-banner--books {
    padding: 40px 0;
    height: 603px;

    .homepage-banner__content {
      .text-small {
        display: none;
      }
    }

    .md-text-small {
      display: block;
      z-index: 2;
      position: absolute;
      width: 230px;
      bottom: 20px;
      left: calc((100vw - map-get($container-max-widths, 'md')) / 2 + 16px);
      align-self: flex-end;
      margin-bottom: 0;
    }

    .become-member-btn {
      margin-bottom: 0px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .homepage-banner--books {
    padding: 62px 0 108px;
    height: unset;

    .homepage-banner_img::after {
      background: unset;
    }

    .homepage-banner__content {
      justify-content: flex-start;
      align-items: center;
    }

    .homepage-banner__content {
      .text-small {
        display: block;
      }
    }

    .md-text-small {
      display: none;
    }

    .become-member-btn {
      margin-bottom: 48px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .homepage-banner--books {
    padding: 193px 0 209px;
  }
}
</style>
